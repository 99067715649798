import { Footer } from '@mantine/core';

// type AppFooterProps = {};

export default function AppFooter() {
  return (
    <Footer height={120}>
      <div>Footer stuff goes here</div>
    </Footer>
  );
}
